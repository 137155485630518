import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto space-y-8 p-3">
      <header className="text-center space-y-2">
        <h1 className="text-3xl font-bold">
          POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES
        </h1>
        <p className="text-gray-600 italic">Última actualización: [Fecha]</p>
      </header>

      <div className="space-y-6">
        <p>
          La presente Política de Privacidad describe las prácticas de{" "}
          <strong>BakersDev LLC</strong> (en adelante, la “Empresa”, “nosotros”
          o “nuestro”) con respecto a la recopilación, uso, almacenamiento,
          divulgación y protección de la información y datos personales que se
          obtienen de los usuarios (en adelante, el “Usuario” o los “Usuarios”)
          de la aplicación móvil <strong>Poncho</strong> (en adelante, la
          “Aplicación”).
        </p>

        <p>
          Esta Política de Privacidad se elabora tomando como base la normativa
          de protección de datos aplicable en los territorios donde operamos. El
          objetivo es garantizar que el tratamiento de los datos personales del
          Usuario se lleve a cabo conforme a los principios de licitud, lealtad,
          transparencia, seguridad, confidencialidad y demás disposiciones
          exigidas en dicha normativa.
        </p>

        {/* --- Section 1 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">
            1. IDENTIDAD Y DATOS DE CONTACTO DEL RESPONSABLE DEL TRATAMIENTO
          </h2>
          <ul className="list-disc pl-8 space-y-2">
            <li>
              <strong>Nombre legal</strong>: BakersDev LLC
            </li>
            <li>
              <strong>Dirección</strong>: [Dirección Completa]
            </li>
            <li>
              <strong>Correo electrónico de contacto</strong>: [Correo para
              consultas de privacidad]
            </li>
            <li>
              <strong>Teléfono</strong>: [Número de teléfono]
            </li>
          </ul>
          <p>
            La Empresa es la responsable del tratamiento de los datos personales
            que el Usuario facilite o que sean generados con ocasión del uso de
            la Aplicación Poncho.
          </p>
        </section>

        {/* --- Section 2 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">2. ÁMBITO DE APLICACIÓN</h2>
          <p>
            Esta Política de Privacidad se aplica a todos los datos personales
            que obtengamos del Usuario al utilizar la Aplicación Poncho. La
            Aplicación tiene como finalidad ofrecer adelantos monetarios de
            salario a Usuarios que formen parte de una empresa o entidad que
            colabora con nosotros (en adelante, la “Empresa Cliente”).
          </p>
        </section>

        {/* --- Section 3 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">
            3. DATOS PERSONALES QUE RECOPILAMOS
          </h2>
          <p>
            Para prestar de manera adecuada los servicios de adelantos
            salariales, necesitamos recopilar ciertos datos personales del
            Usuario. Dependiendo de las funcionalidades utilizadas, podemos
            recolectar:
          </p>

          <div className="space-y-4 pl-8">
            <div>
              <h3 className="font-bold">1. Datos de identificación</h3>
              <ul className="list-disc pl-8">
                <li>Nombre y apellidos.</li>
                <li>Documento de identidad o número de pasaporte.</li>
                <li>Fecha de nacimiento.</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">2. Datos de contacto</h3>
              <ul className="list-disc pl-8">
                <li>Número de teléfono.</li>
                <li>Correo electrónico (personal o corporativo).</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">3. Datos laborales</h3>
              <ul className="list-disc pl-8">
                <li>Cargo, área o departamento en la Empresa Cliente.</li>
                <li>
                  Detalles de la relación contractual o laboral (fecha de
                  inicio, estado de empleo).
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">4. Datos financieros</h3>
              <ul className="list-disc pl-8">
                <li>
                  Información de nómina y salario (importe, frecuencia de pago,
                  deducciones).
                </li>
                <li>
                  Número de cuenta bancaria o información necesaria para
                  efectuar transferencias.
                </li>
                <li>
                  Historial de transacciones de adelantos o pagos anteriores.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">5. Datos de uso y navegación</h3>
              <ul className="list-disc pl-8">
                <li>
                  Información técnica del dispositivo (dirección IP, sistema
                  operativo, modelo).
                </li>
                <li>
                  Registros de acceso y actividad en la Aplicación (fecha y hora
                  de conexión, secciones visitadas).
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">6. Datos de geolocalización</h3>
              <ul className="list-disc pl-8">
                <li>
                  En caso de que la Aplicación requiera confirmar la ubicación
                  para ofrecer funcionalidades específicas.
                </li>
                <li>
                  Solo se recopilarán si el Usuario otorga su consentimiento y
                  habilita la localización en el dispositivo.
                </li>
              </ul>
            </div>
          </div>

          <p>
            Estos datos se recaban de forma directa cuando el Usuario los
            proporciona al registrarse o actualizar su perfil, y de manera
            automática cuando el Usuario interactúa con la Aplicación.
          </p>
        </section>

        {/* --- Section 4 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">4. FINALIDADES DEL TRATAMIENTO</h2>
          <p>
            Los datos personales proporcionados o recopilados se tratan para:
          </p>
          <div className="pl-8 space-y-4">
            <div>
              <h3 className="font-bold">
                1. Gestionar los adelantos de salario
              </h3>
              <ul className="list-disc pl-8">
                <li>
                  Verificar la información laboral, calcular los montos
                  disponibles y procesar las transferencias.
                </li>
                <li>
                  Permitir la integración con la Empresa Cliente que nos
                  confirma la nómina correspondiente.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">
                2. Cumplir con obligaciones contractuales y legales
              </h3>
              <ul className="list-disc pl-8">
                <li>Administrar los servicios solicitados por el Usuario.</li>
                <li>
                  Cumplir con obligaciones derivadas de acuerdos con la Empresa
                  Cliente.
                </li>
                <li>
                  Atender requerimientos de autoridades competentes y asegurar
                  el cumplimiento de la normativa aplicable.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">
                3. Prevenir fraudes y actividades ilícitas
              </h3>
              <ul className="list-disc pl-8">
                <li>
                  Realizar verificaciones y monitorizar el uso de la Aplicación
                  para detectar comportamientos irregulares.
                </li>
                <li>
                  Proteger la información y los activos de la Empresa y del
                  Usuario.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">
                4. Mejorar la experiencia del Usuario
              </h3>
              <ul className="list-disc pl-8">
                <li>
                  Realizar análisis internos (estadísticos y de rendimiento)
                  para optimizar la Aplicación.
                </li>
                <li>
                  Desarrollar nuevas funcionalidades en base a las necesidades
                  detectadas.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">
                5. Comunicaciones operativas y de servicio
              </h3>
              <ul className="list-disc pl-8">
                <li>
                  Enviar notificaciones relacionadas con las transacciones de
                  adelanto salarial, actualizaciones de la Aplicación y cambios
                  en la Política de Privacidad.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold">
                6. Envío de información comercial (opcional)
              </h3>
              <ul className="list-disc pl-8">
                <li>
                  Proporcionar ofertas o promociones relacionadas con servicios
                  financieros o productos similares, siempre que el Usuario lo
                  autorice.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* --- Section 5 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">
            5. BASES PARA EL TRATAMIENTO DE LOS DATOS
          </h2>
          <p>
            Tratamos los datos personales del Usuario únicamente en la medida en
            que exista un fundamento que lo legitime y cuando sea necesario:
          </p>
          <div className="pl-8 space-y-4">
            <div>
              <h3 className="font-bold">
                1. Ejecución de la relación contractual
              </h3>
              <p>
                Para ofrecer los servicios de la Aplicación Poncho y gestionar
                los adelantos de salario, en cumplimiento de los acuerdos con la
                Empresa Cliente y con el propio Usuario.
              </p>
            </div>
            <div>
              <h3 className="font-bold">
                2. Cumplimiento de obligaciones legales
              </h3>
              <p>
                Para acatar las disposiciones vigentes que resulten aplicables,
                incluyendo requerimientos de autoridades u organismos
                competentes.
              </p>
            </div>
            <div>
              <h3 className="font-bold">3. Interés legítimo</h3>
              <p>
                Para prevenir fraudes, mejorar nuestros servicios y garantizar
                la seguridad de la información y de la Aplicación.
              </p>
            </div>
            <div>
              <h3 className="font-bold">4. Consentimiento del Usuario</h3>
              <p>
                Para comunicaciones comerciales, datos de geolocalización y
                cualquier otro tratamiento que no encaje en los apartados
                anteriores, siempre que el Usuario haya dado su autorización de
                manera libre y explícita.
              </p>
            </div>
          </div>
        </section>

        {/* --- Section 6 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">6. CONSERVACIÓN DE LOS DATOS</h2>
          <p>
            Los datos serán conservados mientras se mantenga la relación
            contractual con la Empresa Cliente y, posteriormente, durante el
            plazo que establezca la normativa aplicable o que sea necesario para
            cumplir obligaciones de tipo legal, fiscal, contable, o para la
            defensa ante posibles reclamaciones legales.
          </p>
          <p>
            Una vez transcurridos estos plazos, los datos se eliminarán o se
            anonimizarán de forma segura para que no sea posible la
            identificación de los titulares.
          </p>
        </section>

        {/* --- Section 7 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">
            7. DESTINATARIOS Y COMUNICACIÓN DE DATOS A TERCEROS
          </h2>
          <p>
            Podremos compartir los datos personales del Usuario únicamente con:
          </p>
          <div className="pl-8 space-y-4">
            <div>
              <h3 className="font-bold">1. Empresa Cliente</h3>
              <p>
                Para confirmar la información de nómina y tramitar los adelantos
                de salario.
              </p>
            </div>
            <div>
              <h3 className="font-bold">2. Instituciones financieras</h3>
              <p>
                Para procesar los pagos y transferencias requeridas por los
                Usuarios.
              </p>
            </div>
            <div>
              <h3 className="font-bold">
                3. Proveedores de servicios de tecnología y soporte
              </h3>
              <p>
                Encargados que nos ayudan en la infraestructura tecnológica,
                seguridad, alojamiento de datos y soporte general. Estos
                proveedores tratarán los datos siguiendo nuestras instrucciones
                y aplicando medidas de seguridad apropiadas.
              </p>
            </div>
            <div>
              <h3 className="font-bold">4. Autoridades competentes</h3>
              <p>
                En caso de requerimiento legal o para el cumplimiento de
                obligaciones que resulten exigibles.
              </p>
            </div>
          </div>
          <p>
            Si fuese necesario transferir los datos fuera del país de origen del
            Usuario, se aplicarán las salvaguardas correspondientes para
            garantizar un nivel adecuado de protección de los datos personales.
          </p>
        </section>

        {/* --- Section 8 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">8. DERECHOS DEL USUARIO</h2>
          <p>
            El Usuario puede ejercer, en cualquier momento, los derechos que le
            asistan conforme a la normativa de protección de datos aplicable.
            Estos pueden incluir:
          </p>
          <div className="pl-8 space-y-4">
            <div>
              <h3 className="font-bold">1. Acceso</h3>
              <p>
                Conocer si estamos tratando sus datos y obtener copia de los
                mismos.
              </p>
            </div>
            <div>
              <h3 className="font-bold">2. Rectificación</h3>
              <p>Solicitar la modificación de datos inexactos o incompletos.</p>
            </div>
            <div>
              <h3 className="font-bold">3. Supresión</h3>
              <p>
                Pedir la eliminación de sus datos personales cuando dejen de ser
                necesarios para los fines para los que fueron recabados.
              </p>
            </div>
            <div>
              <h3 className="font-bold">4. Limitación del tratamiento</h3>
              <p>
                Restringir temporalmente el uso de sus datos en determinados
                supuestos.
              </p>
            </div>
            <div>
              <h3 className="font-bold">5. Oposición</h3>
              <p>
                Negarse a que sus datos sean tratados en determinadas
                circunstancias, por ejemplo, para fines de marketing.
              </p>
            </div>
            <div>
              <h3 className="font-bold">6. Portabilidad</h3>
              <p>
                Solicitar que sus datos se trasladen a otro responsable o que se
                los entreguemos en un formato estructurado y de uso común.
              </p>
            </div>
            <div>
              <h3 className="font-bold">7. Revocar el consentimiento</h3>
              <p>
                Retirar en cualquier momento la autorización concedida para
                fines específicos, sin que ello afecte la licitud de los
                tratamientos previos a la revocación.
              </p>
            </div>
          </div>
          <p>
            Para ejercer cualquiera de estos derechos, el Usuario puede
            dirigirse a la Empresa a través del correo electrónico o la
            dirección postal facilitados en la sección Identidad y Datos de
            Contacto del Responsable del Tratamiento. El Usuario también puede
            presentar una reclamación o queja ante las autoridades competentes
            en materia de protección de datos si considera que no se ha atendido
            correctamente el ejercicio de sus derechos.
          </p>
        </section>

        {/* --- Section 9 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">9. MEDIDAS DE SEGURIDAD</h2>
          <p>
            La Empresa aplica las medidas técnicas y organizativas apropiadas
            para proteger los datos personales del Usuario frente a accesos no
            autorizados, pérdidas, divulgaciones o alteraciones indebidas.
            Algunas de las medidas de seguridad incluyen:
          </p>
          <ul className="list-disc pl-8 space-y-2">
            <li>
              Cifrado de la comunicación entre la Aplicación y nuestros
              servidores, si fuera técnicamente viable.
            </li>
            <li>
              Control estricto de acceso a los sistemas donde se almacenan los
              datos.
            </li>
            <li>
              Políticas internas para la gestión y protección de la información.
            </li>
            <li>
              Sistemas de seguridad y monitorización frente a intrusiones.
            </li>
          </ul>
        </section>

        {/* --- Section 10 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">
            10. COOKIES Y TECNOLOGÍAS SIMILARES
          </h2>
          <p>
            La Aplicación Poncho podría utilizar herramientas que, de forma
            análoga a las cookies, recojan información sobre el uso de la misma
            para mejorar la experiencia del Usuario y optimizar su rendimiento.
            Los Usuarios podrán configurar sus dispositivos para restringir,
            bloquear o borrar estas herramientas, sin perjuicio de que algunas
            funcionalidades de la Aplicación puedan verse afectadas.
          </p>
        </section>

        {/* --- Section 11 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">11. MENORES DE EDAD</h2>
          <p>
            La Aplicación no está destinada a menores de 18 años. La Empresa no
            recopila deliberadamente información de menores sin la debida
            autorización. Si se detectara que, por error, se han recogido datos
            de un menor sin el debido consentimiento, se procederá a su
            eliminación inmediata.
          </p>
        </section>

        {/* --- Section 12 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">
            12. MODIFICACIONES A ESTA POLÍTICA
          </h2>
          <p>
            La Empresa se reserva el derecho de modificar esta Política de
            Privacidad de acuerdo con novedades legislativas, cambios
            organizativos o de servicios. En caso de que se realicen ajustes
            sustanciales, se informará al Usuario a través de la Aplicación o de
            un comunicado específico. Se recomienda revisar periódicamente esta
            Política para mantenerse actualizado sobre las prácticas de
            protección de datos.
          </p>
        </section>

        {/* --- Section 13 --- */}
        <section className="space-y-4">
          <h2 className="text-2xl font-bold">13. CONTACTO</h2>
          <p>
            Para cualquier duda o solicitud relacionada con esta Política de
            Privacidad, el Usuario puede dirigirse a:
          </p>
          <ul className="list-disc pl-8 space-y-2">
            <li>
              <strong>Correo electrónico</strong>: [Correo de contacto para
              privacidad]
            </li>
            <li>
              <strong>Dirección postal</strong>: [Dirección Completa de
              BakersDev LLC]
            </li>
          </ul>
          <p>
            Al utilizar la Aplicación Poncho, el Usuario reconoce haber leído y
            comprendido la presente Política de Privacidad y consiente de manera
            libre, específica, informada e inequívoca el tratamiento de sus
            datos personales conforme a lo aquí establecido.
          </p>
        </section>

        <footer className="text-center border-t pt-6 mt-12">
          <p className="font-bold">BakersDev LLC</p>
          <p className="text-gray-600">
            [Fecha de entrada en vigor de la Política]
          </p>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
