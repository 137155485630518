import {
  createRootRoute,
  createRoute,
  createRouter,
  Outlet,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { Home } from "./pages/home";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { DataDeletion } from "./pages/dataDeletion";

const rootRoute = createRootRoute({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: Home,
});

const privacyPolicyRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/privacy-policy",
  component: PrivacyPolicy,
});

const dataDeletionRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/data-deletion",
  component: DataDeletion,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  privacyPolicyRoute,
  dataDeletionRoute,
]);

export const router = createRouter({ routeTree });
