import { useState } from "react";
import { MdEmail, MdError, MdLock } from "react-icons/md";

export const DataDeletion = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Please fill in all fields");
      return;
    }
    // Here you would typically make an API call to handle the deletion request
    setSubmitted(true);
    setError("");
  };

  if (submitted) {
    return (
      <div className="max-w-md mx-auto mt-8 p-6 bg-red-50 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold text-red-700 mb-4">
          Request Received
        </h2>
        <p className="text-gray-700">
          We have received your data deletion request. Please note that this
          process is irreversible. You will receive a confirmation email
          shortly.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Request Data Deletion
        </h2>
        <p className="text-gray-600">
          This action will permanently delete all your data. This process cannot
          be undone.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Email Address
          </label>
          <div className="relative">
            <MdEmail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="pl-10 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-500 focus:border-red-500"
              placeholder="Enter your email"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Password
          </label>
          <div className="relative">
            <MdLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="pl-10 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-500 focus:border-red-500"
              placeholder="Enter your password"
            />
          </div>
        </div>

        {error && (
          <div className="flex items-center space-x-2 text-red-600">
            <MdError className="h-5 w-5" />
            <span className="text-sm">{error}</span>
          </div>
        )}

        <div className="pt-4">
          <button
            type="submit"
            className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
          >
            Request Data Deletion
          </button>
        </div>

        <p className="text-sm text-gray-500 mt-4">
          By submitting this form, you acknowledge that this action is permanent
          and cannot be reversed.
        </p>
      </form>
    </div>
  );
};
